<template>
    <div class="modal-select-date">
        <section>
            <div class="title">날짜 선택</div>
            <div class="calendar">
                <div class="monthly-box">
                    <img
                        width="6"
                        class="arrow"
                        src="@/assets/images/calendar/monthly-icon.svg"
                        @click="onDecrease()"
                    />
                    <span class="monthly-text">{{ date.format('YYYY.MM') }}</span>
                    <img
                        width="6"
                        class="arrow"
                        src="@/assets/images/calendar/monthly-icon.svg"
                        @click="onIncrease()"
                    />
                </div>
                <div class="grid-block">
                    <span class="week" v-for="w in week" :key="w" v-html="w" />
                    <span class="empty-days" v-if="firstDayOfWeek() !== 0" :style="`gridColumn: ${firstDayOfWeek()}`" />
                    <!-- 만약에 0이면 작동?  -->
                    <div
                        class="days"
                        :class="{
                            today: d === today,
                            selected: d === selectedDate,
                            last: d < today,
                        }"
                        v-for="(d, l) in lastDayofMonth()"
                        :key="l"
                        @click="clickDate(d)"
                    >
                        <span class="inner" v-html="d"></span>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <TimePicker @setTime="setTime" />
        </section>
        <div class="button-wrapper m-t-20">
            <div class="confirm" v-html="$translate('CONFIRM')" @click="confirmDate" />
            <div class="cancel m-r-16" v-html="$translate('CANCEL')" @click="$emit('close')" />
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
    name: 'ModalSelectDate',
    data: () => ({
        date: dayjs(),
        selectedDate: dayjs(),
        time: null,
    }),
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        week() {
            return ['일', '월', '화', '수', '목', '금', '토']
        },
        today() {
            return dayjs().year() === this.date.year() && dayjs().month() === this.date.month() && this.date.date()
        },
    },
    methods: {
        setTime(val) {
            this.time = val
        },
        confirmDate() {
            const submitDate = typeof this.selectedDate === 'number'
            const submitTime = this.time && this.time.A && this.time.hh && this.time.mm
            if (!(submitDate && submitTime)) {
                this.$toast.error('날짜/시간을 선택해주세요')
                return
            }
            const time =
                this.time.A === 'AM'
                    ? `${Number(this.time.hh) === 12 ? '00' : this.time.hh}:${this.time.mm}`
                    : `${Number(this.time.hh) === 12 ? 12 : Number(this.time.hh) + 12}:${this.time.mm}`

            this.$emit('close', {
                y: this.date.$y,
                m: this.date.$M + 1 < 10 ? `0${this.date.$M + 1}` : this.date.$M + 1,
                d: this.selectedDate < 10 ? `0${this.selectedDate}` : this.selectedDate,
                time: time,
            })
        },
        clickDate(day) {
            if (day < this.today) return

            this.selectedDate = day
        },
        firstDayOfWeek() {
            return this.date.set('date', 1).day()
        },
        lastDayofMonth() {
            return Array.from({ length: this.date.daysInMonth() }, (x, k) => k + 1)
        },
        onDecrease() {
            this.date = this.date.add(-1, 'month')
            this.selectedDate = 0
        },
        onIncrease() {
            this.date = this.date.add(1, 'month')
            this.selectedDate = 0
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-container-calendar {
    min-width: 320px !important;

    .title {
        font-size: 18px;
        color: black;
        margin-bottom: 16px;
        @include spoqa-f-medium;
    }
}
.button-wrapper {
    display: flex;
    flex-direction: row-reverse;

    .cancel {
        width: fit-content;
    }
    .confirm {
        @extend .cancel;
        color: $blue-primary;
    }
}
div.calendar {
    display: flex;
    flex-direction: column;

    @include spoqa-f-regular;

    div.monthly-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        span.monthly-text {
            font-size: 16px;
        }

        img.arrow {
            cursor: pointer;

            &:last-child {
                transform: rotate(180deg);
            }
        }
    }
    div.grid-block {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-column-gap: 4px;
        grid-row-gap: 20px;
        margin-bottom: 20px;

        span.week {
            color: #949498;
            text-align: center;
            font-size: 12px;
        }

        div.days {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            //margin-bottom: 20px;
            height: 15px;

            span.inner {
                position: absolute;
                font-size: 12px;
            }
        }

        .today {
            position: relative;

            span.inner {
                color: black;
            }

            &:before {
                width: 24px;
                height: 24px;
                display: block;
                content: '';
                background-color: #f1f2f4;
                border-radius: 50%;
                position: absolute;
            }
        }

        .selected {
            position: relative;

            span.inner {
                color: white;
            }

            &:before {
                width: 24px;
                height: 24px;
                display: block;
                content: '';
                background-color: #2898ff;
                border-radius: 50%;
                position: absolute;
            }
        }

        .last {
            color: #c8c8cd;
        }
    }
}
</style>
